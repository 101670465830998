const POTENCY = 'potencyProfile';
const TERPENES = 'terpeneProfile';
const MICRO = 'microbiology';
const PESTICIDES = 'pesticideResidue';
const PESTICIDES_MORE = 'pesticidesResidueMore';
const RESIDUAL_SOLVENTS = 'residueSolvents';
const HEAVY_METALS = 'heavyMetals';
const MYCOTOXINS = 'mycotoxins';
const SULFUR = 'sulfur';
const WATER_ACTIVITY = 'waterActivity';
const MOISTURE = 'moisture';
const FOREIGN_MATERIALS = 'foreignMaterials';
const ALKALOID = 'alkaloid';
const COLIFORM_AND_ECOLI = 'coliform_and_ecoli';
const YEAST_AND_MOLD = 'yeast_and_mold';
const SALMONELLA = 'salmonella';
const ANISIDINE = 'anisidine';
const OTHER_ANALYSES = 'otherAnalyses'; // Water Activity, Moisture, Foreign Materials, Alkaloid Profiles

(function() {
    'use strict';

    angular
        .module('anrescowebApp')
		.service('DownloadSampleService', function () {
			this.showToast = function(message, className) {
				var toastContainer = document.getElementById('toast-container');
				var toast = document.createElement('div');
				toast.className = 'toast ' + className; 
				toast.textContent = message;
				toastContainer.appendChild(toast);
				setTimeout(function() {
					toastContainer.removeChild(toast);
				}, 3000); 
			};
			this.info = function(message) {
				this.showToast(message, 'info-toast');
			};
			this.error = function(message) {
				this.showToast(message, 'error-toast');
			};
		})
        .controller('SampleLogController', SampleLogController)
        .directive('hoverSampleResults', function(){
        	return {
        		link: function ($scope, element, attrs){
        			setTimeout(function(){
        				var $el = $('.hover-sample-results-' + attrs.hoverSampleIndex);
            			element.bind('mouseenter', function(event){
            				$el.offset({top: event.pageY - $el.height()/2});
            				// Determine if part of div is offscreen
            				$el.show();
            				var docViewTop = $(window).scrollTop();
            				var docViewBottom = docViewTop + $(window).height();

            				var elemTop = $el.offset().top;
            				var elemBottom = elemTop + $el.height();

            				if (elemBottom > docViewBottom - 48){
            					$el.offset({top: docViewBottom - $el.height() - 48});
            				} else if (elemTop < docViewTop){
            					$el.offset({top: docViewTop + 24});
            				}

            				var elemLeft = $el.offset().left;
            				var elemRight = elemLeft + $el.width();

            				if (elemRight > window.innerWidth) {
            					$el.offset({left: window.innerWidth - $el.width() - 48});
            				} else if (elemLeft < 0) {
            					$el.offset({left: 24});
            				}

            			});

            			element.bind('mouseleave', function(event) {
            				var pointerEl = document.elementFromPoint(event.clientX, event.clientY);
            				if (pointerEl && pointerEl.closest('.hover-sample-results-div')) {
            					// do nothing
            				} else {
            					$el.hide();
            				}
            			});
            			$el.bind('mouseleave', function(){
            				$el.hide();
            			});

        			}, 1000);

        		}
        	}

        }).directive('hoverDisplayResults', function() {
        	return {
        		scope: {sample: '=', determineTestPf: '='},
        		link: function(scope, element, attrs) {
        			var html = '';
					scope.sample.sampleTests.forEach( function(test) {
						html += scope.determineTestPf(test);
					});

					element.html(html);
				}
			}
        }).directive('displaySampleStatus', function(){
        	return {
            	scope: {sample: '='},
            	link: function(scope, element, attrs) {




                	var PASS_HTML = '<span class="sample-pass"><span class="fa fa-check-circle" title="Pass"></span>&nbsp; </span>';
                	var FAIL_HTML = '<span class="sample-fail"><span class="fa fa-times-circle" title="Fail"></span>&nbsp; </span>';
                	var IN_PROGRESS_HTML = '<span class="sample-in-progress"><span class="fa fa-clock-o" title="In Progress"></span>&nbsp; </span>';
                	var NO_STATUS_HTML = '<span class="sample-no-status"><span class="fa fa-stop-circle"></span>&nbsp; </span>';
                	var CAUTION_HTML = '<span class="sample-caution"><span class="fa fa-warning" title="Caution">&nbsp; </span></span>';
                	var RETESTING_HTML = '<span class="sample-retesting"><span class="fa fa-repeat" title="Retesting">&nbsp; </span></span>';
					var TESTED_HTML = '<span class="sample-tested"><span class="fa fa-stop-circle" title="Tested"></span>&nbsp; </span>';

                	var sample = scope.sample;
                	var status = scope.sample.status;
					var sampleTest = scope.sample.sampleTests[0];

                	if (sample.rd) {
                		if (sample.complete) {
							if (sampleTest.worksheetData) {
								if (sampleTest.worksheetData.do_not_show_pass_fail_in_portal) {
									if (sampleTest.worksheetData.do_not_show_pass_fail_in_portal.value){
										if (status === 'COMPLETED') element.html(TESTED_HTML);
										if (status === 'PASS') element.html(TESTED_HTML);
	                					if (status === 'FAIL') element.html(TESTED_HTML);
	                					if (status === 'IN_PROGRESS') element.html(IN_PROGRESS_HTML);
	                					if (status === 'NO_STATUS') element.html(NO_STATUS_HTML);
	                					if (status === 'CAUTION') element.html(CAUTION_HTML);
	                					if (status === 'RETESTING') element.html(RETESTING_HTML);
									}
									else {
										if (status === 'COMPLETED') element.html(TESTED_HTML);
										if (status === 'PASS') element.html(PASS_HTML);
                						if (status === 'FAIL') element.html(FAIL_HTML);
                						if (status === 'IN_PROGRESS') element.html(IN_PROGRESS_HTML);
                						if (status === 'NO_STATUS') element.html(NO_STATUS_HTML);
                						if (status === 'CAUTION') element.html(CAUTION_HTML);
                						if (status === 'RETESTING') element.html(RETESTING_HTML);
									}
								}
								else {
									if (status === 'COMPLETED') element.html(TESTED_HTML);
									if (status === 'PASS') element.html(PASS_HTML);
                					if (status === 'FAIL') element.html(FAIL_HTML);
                					if (status === 'IN_PROGRESS') element.html(IN_PROGRESS_HTML);
                					if (status === 'NO_STATUS') element.html(NO_STATUS_HTML);
                					if (status === 'CAUTION') element.html(CAUTION_HTML);
                					if (status === 'RETESTING') element.html(RETESTING_HTML);
								}
							}
                		}
						else {
                			if (status) element.html(IN_PROGRESS_HTML);
                			if (status === 'RETESTING') element.html(RETESTING_HTML);
                		}
                	}
					else {
                		if (sample.complete && sample.canSeeReport) {
							if (status === 'COMPLETED') element.html(TESTED_HTML);
                			if (status === 'PASS') element.html(PASS_HTML);
                			if (status === 'FAIL') element.html(FAIL_HTML);
                			if (status === 'IN_PROGRESS') element.html(IN_PROGRESS_HTML);
                			if (status === 'CAUTION') element.html(CAUTION_HTML);
                			if (status === 'RETESTING') element.html(RETESTING_HTML);
                		}
						else {
                			if (status) element.html(IN_PROGRESS_HTML);
                			if (status === 'RETESTING') element.html(RETESTING_HTML);
							if (status === 'COMPLETED') element.html(TESTED_HTML);
                		}
                	}

            	}
        	}
        });

    SampleLogController.$inject = ['$state','$stateParams','$scope','$rootScope', '$filter', '$timeout', 'Sample', 'SampleSearch', 'ParseLinks', 'DateUtils',
        'AlertService', 'paginationConstants', 'pagingParams','Auth', 'Principal', 'ProfileService', 'LoginService', 'SampleFilter', 'Weedmaps',
        'CustomerByUser','SampleAttach','SampleAttachDownload', 'SampleExportService', 'ProductType', 'ProductCategory','ProductTypeByCategory', 'SampleService', 'SampleCoaPdfService','DownloadSampleService','$translate'];

    function SampleLogController($state, $stateParams,$scope, $rootScope, $filter, $timeout, Sample, SampleSearch, ParseLinks, DateUtils, AlertService,
                                 paginationConstants, pagingParams,Auth, Principal, ProfileService, LoginService, SampleFilter, Weedmaps,
                                 CustomerByUser,SampleAttach,SampleAttachDownload, SampleExportService, ProductType, ProductCategory,
                                 ProductTypeByCategory, SampleService, SampleCoaPdfService,DownloadSampleService, $translate) { /*,Auth, Principal, ProfileService, LoginService*/

		var vm = this;
		vm.currentLanguage = $translate.proposedLanguage();
        $rootScope.showSearch = pagingParams.search;
        vm.search = search;
        vm.clear = clear;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.pagingParams = pagingParams;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.getAttachmentsLoader = false;
        vm.itemsPerPage = "20";

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
		vm.setpagesize = setpagesize;

        if(!vm.isAuthenticated()){
            $state.go('login');
        }

		setPageTitle();
		getcannabinoidWorksheetIds();
		getCustomerData();

		$scope.determineTestPf =  function(test) {
			var html = null;

			html += '<tr>';

			html += '<tr><td>' + test.assay.title + '</td>';
			if (test.canSeeResults) {
				if (test.state == 'RETESTING') {
					html += '<td class="test-retesting">Retesting</td>';
				}
				else if (test.caution) {
					html += '<td class="test-caution">Caution</td>';
				}

				if (test.worksheetData) {
					if (test.worksheetData.do_not_show_pass_fail_in_portal) {
						if (test.worksheetData.do_not_show_pass_fail_in_portal.value) {
							if (test.state == 'COMPLETED'){
								html += '<td class="test-no-pf-status">Tested<td>';
							}
							else {
								html += '<td class="test-in-progress">In Progress</td>';
							}
						}
						else if (test.statusPf == 'PASS') {
							html += '<td class="test-pass">Pass</td>';
						}
						else if (test.statusPf == 'FAIL') {
							html += '<td class="test-fail">Fail</td>';
						}
						else if (test.state == 'COMPLETED'){
							html += '<td class="test-no-pf-status">Tested<td>';
						}
					}
					else if (test.statusPf == 'PASS') {
						html += '<td class="test-pass">Pass</td>';
					}
					else if (test.statusPf == 'FAIL') {
						html += '<td class="test-fail">Fail</td>';
					}
					else if (test.state == 'COMPLETED'){
						html += '<td class="test-no-pf-status">Tested<td>';
					}
					else {
						html += '<td class="test-in-progress">In Progress</td>';
					}
				}

				// this is for tests without worksheets
				else if (test.results) {
					html += '<td class="test-pass">Tested<td>';
				}
				// this is for test with a consulting worksheet
				else if (test.worksheetData) {
					if (test.worksheetData.findings_1){
						if (test.worksheetData.findings_1.value){
							html += '<td class="test-pass">Tested<td>';
						}
					}
					else {
						html += '<td class="test-in-progress">In Progress</td>';
					}
				}
				else {
					html += '<td class="test-fail">ND</td>';
				}
			}
			else {
				if (test.state == 'RETESTING') {
					html += '<td class="test-retesting">Retesting</td>';
				}
				else {
					html += '<td class="test-in-progress">In Progress</td>';
				}
			}

			html += '</tr>';

			return html;
		}


        if(pagingParams.items != null){
            vm.itemsPerPage = pagingParams.items;
		}

        // Initialize default sample columns in case user does not have custom columns configured
        vm.sampleColumns = {
        		'lotNumber': true,
        		'name': true,
        		'productType': true,
        		'thc': true,
        		'cbd': true,
				'alkaloids': true,
				'mitragynine': false,
        		'dateCompleted': true,
        		'weedmaps': false,
        		'public': false,
        		'downloads': true,
        		'sCode': false,
        		'totalCannabinoids': false,
        		'dateReceived': false,
        		'dateRequired': false,
        		'status': false,
        		'order_id': false
        }

        vm.toggleEditColumns = function(){
        	vm.showEditColumns = !vm.showEditColumns;
        	if (vm.showEditColumns == true) {
        		// pass
        	} else {
        		saveSampleColumns();
        	}
        }

        vm.toggleWidget = function(name) {
        	if (vm.activeWidget === "sampleEditColumns") {
        		saveSampleColumns();
        	}

        	vm.activeWidget = name === vm.activeWidget ? null : name;
        }

        Principal.identity().then(function(account) {
        	vm.userAccount = account;
            if (account.userSettings) {
            	var userSettings = JSON.parse(account.userSettings);
            	if (userSettings.sample_columns){
            		vm.sampleColumns = userSettings.sample_columns;
            	}
            }
		});

		function getcannabinoidWorksheetIds() {
			SampleService.getWorksheetParserMap({},
				function(data) {
					vm.cannabioidWorksheets = data.cannabinoid_profile;
					vm.alkaloidsWorksheets = data.alkaloid;
				},
				function(e) {
					console.log(e);
				}
			);
		}

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.sampleFilters = {
        		id: pagingParams.id,
        		orderId: pagingParams.orderId,
        		sampleName: pagingParams.sampleName,
        		lotNumber: pagingParams.lotNumber,
        		metrcId: pagingParams.metrcId,
        		categoryIdList: pagingParams.categoryIdList,
        		category: pagingParams.category,
        		subCategory: pagingParams.subCategory,
        		orderType: pagingParams.orderType,
        		thcLower: pagingParams.thcLower,
        		thcUpper: pagingParams.thcUpper,
        		cbdLower: pagingParams.cbdLower,
        		cbdUpper: pagingParams.cbdUpper,
        		isPass: pagingParams.isPass == 'true' ? pagingParams.isPass != null : null,
				isFail: pagingParams.isFail == 'true' ? pagingParams.isFail != null : null,
        		isUndetermined: pagingParams.isUndetermined == 'true' ? pagingParams.isUndetermined != null : null,
        		isWeedmapsSuccess: pagingParams.isWeedmapsSuccess == 'true' ? pagingParams.isWeedmapsSuccess != null : null,
        		isWeedmapsFail: pagingParams.isWeedmapsFail == 'true' ? pagingParams.isWeedmapsFail != null : null,
        		isWeedmapsNone: pagingParams.isWeedmapsNone == 'true' ? pagingParams.isWeedmapsNone != null: null,
        		visibility: pagingParams.visibility,
        		completeDateLower: pagingParams.completeDateLower ? convertLocalDateFromServer(pagingParams.completeDateLower) : null,
        		completeDateUpper: pagingParams.completeDateUpper ? convertLocalDateFromServer(pagingParams.completeDateUpper) : null,
        		estimatedDueDateLower: pagingParams.estimatedDueDateLower ? convertLocalDateFromServer(pagingParams.estimatedDueDateLower) : null,
        		estimatedDueDateUpper: pagingParams.estimatedDueDateUpper ? convertLocalDateFromServer(pagingParams.estimatedDueDateUpper) : null,
				starred: pagingParams.starred ? pagingParams.starred : null,
				isInProgress: pagingParams.isInProgress ? pagingParams.isInProgress : null,
				isCompleted: pagingParams.isCompleted ? pagingParams.isCompleted: null
        }

        vm.sort = function() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        /* Filters */
        vm.page = pagingParams.page;
        vm.populateTypes = function() {
        	if (vm.sampleFilters.category) {
        		vm.productTypes = ProductTypeByCategory.query(vm.sampleFilters.category);
        	} else {
        		vm.productTypes = [];
        	}

        }
        vm.productCategories = ProductCategory.query();
		vm.applyFilters = applySampleFilters;

        function applySampleFilters() {
			// Parse filter criteria and add paging params
			var params = createFilterQueryParams();

        	// Send query
            SampleFilter.query(params, onSuccess, onError);
            function onSuccess(result, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.samples = result;
                vm.page = 0;
				vm.pagingParams = pagingParams;
            }
            function onError(error) {
            	console.log("Error applying filter");
            	console.log(error);
            }
        }

        function getFilterCount() {
			var totalFilters = 0;
        	for (var key in vm.sampleFilters) {
        		if (vm.sampleFilters[key] !== null && vm.sampleFilters[key] !== "" && vm.sampleFilters[key] !== undefined && vm.sampleFilters[key] !== false) {
					totalFilters++;
        		}
			}

			vm.totalFilters = totalFilters;
        	return totalFilters;
        }
        getFilterCount();
        vm.getFilterCount = getFilterCount;
        vm.filterSubmit = function() {
			        	vm.page = 1;
			vm.transition();
			vm.setTabViews();
		}

		$scope.all = true;
		vm.switchTab = function(tab) {

			if (tab == 'starred') {
				$scope.starred = true
				$stateParams.starred = true;
			}
			else if (tab == 'isInProgress') {
				$scope.isInProgress = true
				$stateParams.isInProgress = true;
			}
			else if (tab == 'isCompleted') {
				$scope.isCompleted = true
				$stateParams.isCompleted = true;
			}
			else if (tab == 'total') {
				$scope.total = true
				$stateParams.total = true;
			}

			angular.forEach(vm.tabs, function(value, key) {
				if (key == tab) {
					vm.tabs[key] = true;
					$scope.activeTab = tab;
					pagingParams[key] = true;
					$scope[key] ? $scope[key] = true : angular.noop;
					$stateParams[key] ? $stateParams[key] = true : angular.noop;
				}
				else {
					vm.tabs[key] = false;
					pagingParams[key] ? pagingParams[key] = false : angular.noop;
					$scope[key] ? $scope[key] = null : angular.noop;
					$stateParams[key] ? $stateParams[key] = null : angular.noop;
				}
			});
			getFilterCount();
			vm.filterSubmit();
		}

		vm.setTabViews = function() {
			if (pagingParams.starred) {
				$('#starred').addClass('active');
				$('#starred_controls').addClass('active');
				$('#isInProgress').removeClass('active');
				$('#total').removeClass('active');
				$('#isInProgress_controls').removeClass('active');
				$('#total_controls').removeClass('active');
				$('#isCompleted').removeClass('active');
				$('#isCompleted_controls').removeClass('active');
			}
			else if (pagingParams.isInProgress) {
				$('#isInProgress').addClass('active');
				$('#isInProgress_controls').addClass('active');
				$('#starred').removeClass('active');
				$('#total').removeClass('active');
				$('#starred_controls').removeClass('active');
				$('#total_controls').removeClass('active');
				$('#isCompleted').removeClass('active');
				$('#isCompleted_controls').removeClass('active');
			}
			else if (pagingParams.isCompleted) {
				$('#isCompleted').addClass('active');
				$('#isCompleted_controls').addClass('active');
				$('#starred').removeClass('active');
				$('#total').removeClass('active');
				$('#starred_controls').removeClass('active');
				$('#total_controls').removeClass('active');
				$('#isInProgress').removeClass('active');
				$('#isInProgress_controls').removeClass('active');
			}
			else {
				$('#total').addClass('active');
				$('#total_controls').addClass('active');
				$('#starred').removeClass('active');
				$('#isInProgress').removeClass('active');
				$('#starred_controls').removeClass('active');
				$('#isInProgress_controls').removeClass('active');
				$('#isCompleted').removeClass('active');
				$('#isCompleted_controls').removeClass('active');
			}
		}
		vm.setTabViews();

		vm.tabs = {
			total: totalOnly(),
			isInProgress: pagingParams.isInProgress ? pagingParams.isInProgress : false,
			starred: pagingParams.starred ? pagingParams.starred : false,
			isCompleted: pagingParams.isCompleted ?pagingParams.isCompleted : false,
			external: false
		}

		function totalOnly() {
			if (!pagingParams.isInProgress && !pagingParams.starred && !pagingParams.isCompleted) {
				return true;
			}
			else {
				return false;
			}
		}

        function createFilterQueryParams() {
        	var params = {};
            var dateFields = ["completeDateLower", "completeDateUpper", "estimatedDueDateLower", "estimatedDueDateUpper"];
        	for (var key in vm.sampleFilters) {
        		if (dateFields.indexOf(key) !== -1) {
         			params[key] = convertLocalDateToServer(vm.sampleFilters[key]);
         		} else if (vm.sampleFilters[key] !== "" && vm.sampleFilters[key] !== null && vm.sampleFilters[key] !== undefined) {
        			params[key] = vm.sampleFilters[key];
        		}
        	}

        	params.page = vm.page;
        	params.sort = vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc');
            params.search = vm.currentSearch;
            params.size = vm.itemsPerPage;
            params.items = params.size;
			params.productCategoryId = vm.productCategoryId;


			angular.forEach(vm.tabs, function(value, key) {
				if (value == true) {
					pagingParams[key] = true;
				}
			});
        	return params;
		}


		function createAttributesCountDict() {
			var dict = {
				starred: [1],
				status: ['IN_PROGRESS'],
				status_copy: ['PASS', 'FAIL', 'COMPLETED'],
				total: [true]
			};

			var filters = Object.assign({}, vm.sampleFilters);
			filters.isInProgress = null;
			filters.starred = null;
			filters.isCompleted = null;

			var payload = {
				headers : dict,
				filters: filters
			}

			vm.filterCounts = {}
			return payload
		}

		function createStarredPayload() {
			var dict = {
				starred: 1,
			};

			var filters = Object.assign({}, vm.sampleFilters);
			filters.isInProgress = null;
			filters.starred = null;
			filters.isCompleted = null;

			var payload = {
				headers : dict,
				filters: filters
			}

			return payload
		}

		function getStarredSamplesCount() {
			SampleFilter.attributeCounts(createStarredPayload(),
			function(data){
				vm.filterCounts.starred = data.starred;
			},

			function(e) {
				console.log(e)
			}
		);
		}

		SampleFilter.attributeCounts(createAttributesCountDict(),
			function(data){
				vm.filterCounts.total = data.total;
				vm.filterCounts.starred = data.starred;
				vm.filterCounts.isInProgress = data.status;
				vm.filterCounts.isCompleted = data.status_copy;
			},

			function(e) {
				console.log(e)
			}
		);

        $scope.canSeeTest = function(sample, testName) {
        	var assayIdMap = {
        			'POTENCY_LCMSMS': 'Cannabinoid Profile',
        			'POTENCY_GCFID': 'Cannabinoid Profile',
        			'POTENCY_LCDAD': 'Cannabinoid Profile',
					'POTENCY_LCDAD_NON_PLANT': 'Cannabinoid Profile',
					'POTENCY_PLANT' : 'Cannabinoid Profile',
        			'RESIDUAL_SOLVENT_PHASE_II': 'Residual Solvents',
        			'RESIDUAL_SOLVENT_PHASE_I': 'Residual Solvents',
        			'HEAVY_METALS': 'Heavy Metals',
        			'HEAVY_METALS_FOOD': 'Heavy Metals',
        			'TERPENE_PROFILE': 'Terpene Profile',
        			'PESTICIDES_PLUS': 'Pesticide Screen',
        			'PESTICIDES_PHASE_II': 'Pesticide Screen',
        			'MICRO_QA_SCREEN': 'Microbiology',
        			'MICRO_PLUS': 'Microbiology',
        			'MICRO_INHALABLES': 'Microbiology',
        			'MICRO_NON_INHALABLES': 'Microbiology',
        			'MYCOTOXINS': 'Mycotoxins',
        			'WATER_ACTIVITY': 'Water Activity',
        			'FOREIGN_MATERIAL': 'Foreign Materials',
        			'MOISTURE': 'Moisture',
        			'SULFUR': 'Sulfur',
        			'HOMOGENEITY': 'Homogeneity',
        			'HOMOGENEITY_5': 'Homogeneity',
        			'PESTICIDES_MYCOTOXINS': 'Pesticides + Mycotoxins',
        			'ALKALOID': 'Alkaloid'
        	}
        	for (var i = 0; i < sample.sampleTests.length; i++) {
        		var test = assayIdMap[sample.sampleTests[i].assay.portalIdentifier];
        		if (test === testName) {
        			return sample.sampleTests[i].canSeeResults;
        		}
        	}
        }

        vm.downloadSamplesCsv = function downloadSamplesCsv () {

            var fileName = "samples.csv";
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            SampleExportService.downloadCsv().then(function (result) {
                var file = new Blob([result.data], {type: 'application/json'});
                var fileURL = window.URL.createObjectURL(file);
                a.href = fileURL;
                a.download = fileName;
                a.click();
            });


        }

		function padToTwo(number) {
            return `0${number}`.slice(-2);
		}

		function formatDate(date) {
            var month = date.getUTCMonth() + 1;
            var day = date.getUTCDate();
            var year = date.getUTCFullYear();

            return `${padToTwo(month)}/${padToTwo(day)}/${year}`;
        }

		vm.displayDueDate = function(sample) {
			if(sample.sampleTests.length == 0) return 'Not Set';
			var tests = sample.sampleTests;
			var test = null;
			var current_due_date = null;

			for(var test_index = 0; test_index < tests.length; test_index++) {
				test = tests[test_index];
				if(current_due_date == null) current_due_date = test.estimatedCompleteDate;
				if(test.estimatedCompleteDate != current_due_date) return "Various";
			}

            if(!current_due_date) return "Not Set";

			try {
                current_due_date = new Date(current_due_date);
                if(current_due_date == 'Invalid Date') throw 'Invalid Date'

				return formatDate(current_due_date);
			}
			catch(e) {
				console.log(e);
                return current_due_date;
			}
		}

        vm.updateSamplePublicity = function(sample) {
            vm.getAttachmentsLoader = false;
            sample.attachments = null;
            Sample.public({id: sample.id, public: sample.public});

        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.downloadSampleStartDate = false;
        vm.datePickerOpenStatus.downloadSampleEndDate = false;

		$scope.clickCount = 0;
        vm.openCalendar = function(date) {
			$scope.clickCount++;
            vm.datePickerOpenStatus[date] = true;
			if($scope.clickCount > 2 && vm.downloadSamplesError == true ){
				vm.downloadSamplesError = false;
			}

        }

        vm.downloadSample = {
        		startDate: null,
        		endDate: null,
        		type: 'COA'
        }

        vm.downloadingSamples = false;
        vm.downloadSamplesError = false;
        vm.downloadSamplesErrorMsg = '';
        vm.downloadSamplesNull = false;

        vm.downloadSamples = function() {
        	vm.downloadSamplesError = false;
			vm.downloadSamplesErrorMsg = '';
        	vm.downloadSamplesNull = false;
        	var sampleExportParams = {
                	startDate: vm.downloadSample.startDate ? vm.downloadSample.startDate.getTime()/1000 : null,
                	endDate: vm.downloadSample.endDate ? vm.downloadSample.endDate.getTime()/1000 : null,
                	type: vm.downloadSample.type
        	}
        	vm.downloadingSamples = true;
			
        	if (vm.downloadSample.type == "COA") {
            	SampleExportService.downloadCoa(sampleExportParams).then(function (result) {
                    var fileName = "samples";
                    if (sampleExportParams.startDate && sampleExportParams.endDate) {
                    	fileName += " " + $filter('date')(vm.downloadSample.startDate, 'MM-dd-yyyy') + " to " + $filter('date')(vm.downloadSample.endDate, 'MM-dd-yyyy');
                    } else if (sampleExportParams.startDate) {
                    	fileName += " after " + $filter('date')(vm.downloadSample.startDate, 'MM-dd-yyyy');
                    } else if (sampleExportParams.endDate) {
                    	fileName += " before " + $filter('date')(vm.downloadSample.endDate, 'MM-dd-yyyy');
                    }
                    fileName += ".zip";

            		downloadSamplesSuccess(fileName, result);

                }, function(error) {
                	downloadSamplesError(error);
                });
        	} else {
				var params = {};
				var dateFields = ["completeDateLower", "completeDateUpper", "estimatedDueDateLower", "estimatedDueDateUpper"];
				for (var key in vm.sampleFilters) {
					if (dateFields.indexOf(key) !== -1 && vm.sampleFilters[key] !== "" && vm.sampleFilters[key] !== null && vm.sampleFilters[key] !== undefined) {
						params[key] = convertLocalDateToServer(vm.sampleFilters[key]);
					} else if (vm.sampleFilters[key] !== "" && vm.sampleFilters[key] !== null && vm.sampleFilters[key] !== undefined) {
						params[key] = vm.sampleFilters[key];
					}
				}
				sampleExportParams.filter_details = params
            	SampleExportService.downloadCsv(sampleExportParams).then(function (result) {
            		var fileName = "samples.csv";
            		downloadSamplesSuccess(fileName, result);

                }, function(error) {
                	downloadSamplesError(error);
                });
        	}
        }

        /* Publish to weedmaps */
        vm.publishToWeedmapsArgs = {
        		id: null, // required
        		weedmapsListingId: null, // required
        		weedmapsMenuItemName: null, // required
        		weedmapsMenuItemCategory: null, // optional
        		weedmapsMenuItemId: null // optional
        }

        vm.publishToWeedmaps = function() {
        	function onSuccess(data) {
        		vm.publishToWeedmapsSuccess = true;
        		vm.publishingToWeedmaps = false;
        		$timeout(function(){
        			$('#publishToWeedmapsModal').modal('hide');
        			$state.reload();
        		},2000)
        	}
        	function onError(error) { vm.publishToWeedmapsSuccess = false; vm.publishingToWeedmaps = false; }

        	vm.publishingToWeedmaps = true;
        	Weedmaps.publish(vm.publishToWeedmapsArgs, onSuccess, onError);

        }

        vm.emailsPending = [""];
        vm.emailMessage = "";

        vm.sendPublicEmail = function (form) {
        	vm.emailsPending = $('#testtaginput').tagsinput('items')
        	vm.emailSubject = $('#emailSubject').val();
        	//Send The Email
        	if (form.$valid && vm.emailsPending.length > 0){
        		$('#sendPublicSampleButton').attr("disabled", true);
        		Sample.sendEmail({
        			emails: vm.emailsPending,
        			message: vm.emailMessage,
        			public_link: vm.emailSamplePublicLink,
        			pic_url: vm.currentPublicSample.picture,
        			id:vm.currentPublicSample.id,
        			totalTHC: getTotalThc(vm.currentPublicSample),
        			subject: vm.emailSubject
        		}, onSuccess, onError);
        	}
        	else {
        		if (vm.emailsPending.length == 0)
        			$('#emailValidation').show();
        		return
        	}
        	function onSuccess(data, headers) {
        		$('#emailModal').modal('hide');
        		$('#emailModalSuccess').modal('show');
        		$('#sendPublicSampleButton').attr("disabled", false);

	        }

	        function onError(error) {
	        	AlertService.error(error.data.message);
	        	vm.emailsPending = [""];
	            vm.emailMessage = "";
	            $('#sendPublicSampleButton').attr("disabled", false);

	        }
        }

    $('#emailModalSuccess').on('hidden.bs.modal', function() {
        	$('#testtaginput').tagsinput('removeAll');
        	$('#testtaginput').tagsinput('input')[0].placeholder = 'email@example.com';
        })

        vm.copyLink = function(id) {
    	  /* Get the text field */
    	  var copyText = document.getElementById(id);
    	  /* Select the text field */
    	  copyText.select();
    	  copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    	  /* Copy the text inside the text field */
    	  document.execCommand("copy");
        }

        vm.currentPublicSample = null

        vm.createSamplePublicLink = function(sample) {
        	var hash = sample.hash;
        	var fullUrl = window.location.origin +"/#/ps/"+ sample.hash;
        	vm.currentPublicSample = sample;
        	return fullUrl;
        }

        vm.openWeedmapsModal = function(sample) {
        	if (!sample.complete || !sample.canSeeReport) {
        		AlertService.error("Sample " + sample.id + " is not yet ready to be published", null, 'top right');
        		return;
        	}
            vm.publishToWeedmapsArgs = {
            		id: sample.id, // required
            		weedmapsListingId: parseInt(sample.weedmapsListingId), // required
            		weedmapsMenuItemName: sample.weedmapsMenuItemName, // required
            		weedmapsMenuItemCategory: sample.weedmapsMenuItemCategory, // optional
            		weedmapsMenuItemId: sample.weedmapsMenuItemId // optional
            }
            vm.publishToWeedmapsSuccess = null;
        	$('#publishToWeedmapsModal').modal('show');
        }
        $('#publishToWeedmapsModal').on('hidden.bs.modal', function() { vm.publishToWeedmapsArgs = {}; });

        $scope.getReports = function (sample,id) {
	        vm.getReportsLoader = true;
	        vm.idOfSelectedSample = id;


	        if(sample.reports != null){
		        vm.getReportsLoader = false;
		        return;
	        }

	        SampleAttach.getReports({id: id}, onSuccess, onError);
	        function onSuccess(data, headers) {
		        vm.getReportsLoader = false;
		        sample.reports = data;

	        }
	        function onError(error) {
	        	AlertService.error(error.data.message);
	        }
		}

		$scope.removeElement = function (allAttachments, at) {
			return allAttachments.filter(function (attachment) {
				if (attachment.id !== at.id) return attachment;
			})

		}

		$scope.reorderAttachments = function (attach) {
			var allAttachments = attach.slice();
			var reordered = [];
            if (attach && attach.length) {
                var newOrder = ['_consolidated_coa', '_coa', '_qr_code', 'pictures'];
                newOrder.forEach(function (reg) {
                    attach.forEach(function (at, key) {
						console.log(at.file_name);
                        if (reg === '_consolidated_coa' && at.file_name.toLowerCase().includes('_consolidated_coa')){
							reordered.push(at);
							allAttachments = $scope.removeElement(allAttachments, at);
                        }
                        else if (reg === '_coa' && at.file_name.toLowerCase().includes('_coa')) {
							reordered.push(at);
							allAttachments = $scope.removeElement(allAttachments, at);
                        }
                        else if (reg === '_qr_code' && at.file_name.toLowerCase().includes('_qr_code')) {
							reordered.push(at);
							allAttachments = $scope.removeElement(allAttachments, at);
                        }
                        else if (reg ===  'pictures' && at.is_picture) {
							reordered.push(at);
							allAttachments = $scope.removeElement(allAttachments, at);
						}
                    })
                })
			}
			if (allAttachments.length) {
				for (var i = 0; i < allAttachments.length; i++){
					reordered.push(allAttachments[i]);
				}
			}
            return reordered;
    }

        $scope.getAttachments = function (sample, id) {
	        vm.getAttachmentsLoader = true;
	        vm.idOfSelectedSample = id;

				if(sample.attachments != null){
					vm.getAttachmentsLoader = false;
					return;
				}

				SampleAttach.query({id: id}, onSuccess, onError);
				function onSuccess(data, headers) {
					var processed_attachments = [];

					vm.getAttachmentsLoader = false;
					sample.attachments = data;
					sample.attachments.forEach( function(attachment) {
						if(!attachment.file_name.toLowerCase().includes('label')) {
							processed_attachments.push(attachment)
						}
					})
					sample.attachments = processed_attachments;
					// sample.attachments = $scope.reorderAttachments(processed_attachments, true);
				}
				function onError(error) {
					AlertService.error(error.data.message);
				}
		}

        $scope.toggleVisibility = function(id, sample) {
        	Sample.toggleVisibility({id: id},onSuccess,onError);

        	function onSuccess (data, headers) {
        		sample.is_public = data.is_public
        	}

        	function onError(error) {

		    }
        }

        $scope.toggleStarred = function(id, sample) {
        	Sample.toggleStarred({id: id},onSuccess,onError);

        	function onSuccess (data, headers) {
				sample.starred = data.starred
				getStarredSamplesCount()
        	}

        	function onError(error) {

		    }
		}

		$scope.isWithForeignTest = function (sample) {
			var flag = false;
			var test = sample.sampleTests;
			if (test.length) {
				for (var i = 0; i < test.length; i++){
					var t = test[i];
					if (t.assay.portalIdentifier == 'FOREIGN_MATERIAL') flag = true;
				}
				return flag;
			} else return false;
		}

		$scope.isWithCDPHETest = function (sample) {
			var flag = false;
			var test = sample.sampleTests;
			if (test.length) {
				for (var i = 0; i < test.length; i++){
					var t = test[i];
					if (t.assay.portalIdentifier == 'CO_CDPHE') flag = true;
				}
				return flag;
			} else return false;
		}

		$scope.downloadAttachment = function (id_hash, sample) {
			if(id_hash) {
				if(id_hash == 'coa') {
					var a = document.createElement("a");
					document.body.appendChild(a);
					a.style = "display: none";
					SampleCoaPdfService.downloadPdf(sample.hash).then( function(result) {
						var file = new Blob([result.data], {
							type: 'application/pdf'
						});

						a.href = window.URL.createObjectURL(file);
						a.download = sample.id + '_COA_Summary.pdf';
						a.click();
					});
				}
				else if(['california', 'colorado'].includes(id_hash)) {
					SampleAttachDownload.getBadge({text: window.location.host + '/#/ps/' + sample.hash, state: id_hash}, onSuccess, onError);
					var capitalizedState = id_hash.charAt(0).toUpperCase() + id_hash.slice(1);

					function onSuccess(data, headers) {
						var a = document.createElement("a");
						document.body.appendChild(a);
						a.style = "display: none";

						var fileURL = window.URL.createObjectURL(data.data);
						a.href = fileURL;
						a.download = sample.id + '_' + capitalizedState + '_QR_Code_Badge.png';
						a.click();
					}
					function onError(error) {
						console.log(error);
					}

				}
				else {
					SampleAttachDownload.get({id: id_hash}, onSuccess, onError);

					function onSuccess(data, headers) {
						var a = document.createElement("a");
						document.body.appendChild(a);
						a.style = "display: none";

						var fileURL = window.URL.createObjectURL(data.data);
						a.href = fileURL;
						a.download = headers('content-disposition').substring(21);
						a.click();
					}
					function onError(error) {
						console.log(error);
					}
				}
			}
			sample.select = "";
		}

		$scope.starred = pagingParams.starred ? pagingParams.starred : false;

		vm.toggleStarredView = function() {
			$scope.starred = !$scope.starred;

			vm.filterSubmit();
		}

		$('#testtaginput').tagsinput({
			tagClass: function(item) {
				return 'custom-label label-email'
			}
		});

		$('#testtaginput').on('beforeItemAdd', function(event) {
			var email_re = "^\S+@\S+$";
			if (!event.item.match(/\S+@\S+\.\S+/)) {
				$('#emailValidation').show();
				event.cancel = true;
			} else {
				$('#emailValidation').hide();
				$('#testtaginput').tagsinput('input')[0].placeholder = '';
			}
		});

		$('#testtaginput').on('beforeItemRemove', function(event) {
			if ($('#testtaginput').tagsinput('input')[0].value.length > 0)
				event.cancel = true;
			  // event.item: contains the item
			  // event.cancel: set to true to prevent the item getting removed
		});

		$('#testtaginput').tagsinput('input')[0].type = 'email'
		/*
		 * Gallery View Variables and Functions
		 * */

		$rootScope.galleryView = pagingParams.galleryView ? pagingParams.galleryView : false;

		/* Download Samples */
        function downloadSamplesSuccess(fileName, result) {
    		if (result.data.byteLength == 0) {
    			vm.downloadSamplesNull = true;
    			vm.downloadingSamples = false;
    			return;
    		}

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            var file = new Blob([result.data], {type: 'application/json'});
            var fileURL = window.URL.createObjectURL(file);
            a.href = fileURL;
            a.download = fileName;
            a.click();
            vm.downloadingSamples = false;
        }
        function downloadSamplesError(err) {
			
			var decodedString = new TextDecoder("utf-8").decode(new Uint8Array(err.data));
			var jsonResponse = JSON.parse(decodedString);
			vm.downloadSamplesErrorMsg = jsonResponse.message;
        	vm.downloadingSamples = false;
            vm.downloadSamplesError = true;
			if(err.status === 404){
				vm.downloadSamplesErrorMsg = 'No samples found during selected dates, please select new dates.';
			}

        }

		$scope.clearBtn = function() {
			 vm.downloadSample.startDate = ''
			 vm.downloadSample.endDate = ''
			 vm.downloadSamplesError = false;
		}

        /* Date Utils (import DateUtils service doesn't work) */
        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        /* Login/Logout */
        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        /* Navbar Toggle */
        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }


        function setPageTitle(){
            $rootScope.pageTitle = "Samples";
            $rootScope.searchVm = vm;
		}

		vm.customerType = "";
        function getCustomerData () {
			CustomerByUser.get({}, onSuccess, onError);
            function onSuccess(data, headers) {
				var type_of_business = data.businessType.name.toLowerCase();
				vm.customerData = data;
				try {
					vm.customerType = data.businessType.name;
				}
				catch (error) {
					console.log(error);
				}
				if (type_of_business.includes('food')) {
					vm.customerData.consolidated_business_type = 'food';
					vm.sampleColumnAlkaloids = true;
					vm.sampleColumnMitragynine = true;
				}
				else if (type_of_business.includes('cannabis')) {
					vm.customerData.consolidated_business_type = 'cannabis';
					vm.sampleColumnThc = true;
					vm.sampleColumnCbd = true;
					vm.thcCbdFilterOptions = true;

				}
				else if (type_of_business.includes('hemp')) {
					vm.customerData.consolidated_business_type = 'hemp';
					vm.sampleColumnThc = true;
					vm.sampleColumnCbd = true;
					vm.thcCbdFilterOptions = true;
				}
				else if (type_of_business.includes('kratom')){
					vm.customerData.consolidated_business_type = 'kratom';
					hasAlkaloidsMitragynine();
					vm.sampleColumnAlkaloids = true;
					vm.sampleColumnMitragynine = true;
				}
            }
            function onError(e) {
				console.log(e);
            }
		}
		vm.determinePotencyDisplayForMitragynineAndAlkaloids = function(sample,potencyType,show_dash) {
			if (!sample.sampleTests) {
				return '';
			}
			var potency = '';
			var ws = null;
			var val = 'twoDecimalFormattedValue';
			if(vm.alkaloidsWorksheets){
				for (var i = 0; i < sample.sampleTests.length; i++) {
					if(vm.alkaloidsWorksheets.includes(sample.sampleTests[i].assay.worksheet_id)) {
						if(sample.sampleTests[i].worksheetData) {
							ws = sample.sampleTests[i].worksheetData;
							if(ws[potencyType + '_percent']['value'] == '0') {
								potency = '0%';
							}
							else {
								potency = ws[potencyType + '_percent'][val] ? ws[potencyType + '_percent'][val] + ' %' : '';
							}
						}
					}
				}
			}
			if(show_dash) {
				return potency ? potency : '-';
			}
			else {
				return potency;
			}
		}
		vm.determinePotencyDisplay = function(sample, potencyType, total, show_dash) {
			if (!sample.sampleTests) {
				return '';
			}
			var potency = '';
			var ws = null;

			if(total) {
				potencyType = 'thc';
			}

			var val = 'twoDecimalFormattedValue';

			if(vm.cannabioidWorksheets) {
				for (var i = 0; i < sample.sampleTests.length; i++) {
					if(vm.cannabioidWorksheets.includes(sample.sampleTests[i].assay.worksheet_id)) {
						if(sample.sampleTests[i].worksheetData) {
							ws = sample.sampleTests[i].worksheetData;


							if(ws['total_' + potencyType + '_mgpp']){
								if(!total) {
									if(ws['total_' + potencyType + '_mgpp']['value'] == '0') {
										potency = '0 mg/pkg';
									}
									else {
										potency = ws['total_' + potencyType + '_mgpp'][val] ? ws['total_' + potencyType + '_mgpp'][val] + ' mg/pkg' : '';
									}
								}
								else {
									if( ws['total_mgpp']['value'] == '0') {
										potency = '0 mg/pkg';
									}
									else {
										potency = ws['total_mgpp'][val] ? ws['total_mgpp'][val] + ' mg/pkg' : '';
									}
								}
							}
							if(ws['units_per_package'] && [null, '', '1'].includes(ws['units_per_package']['value']) && ws['total_' + potencyType + '_mgpu'] && potency == '') {
								if(!total) {
									if(ws['total_' + potencyType + '_mgpu']['value'] == '0') {
										potency = '0 mg/serv';
									}
									else {
										potency = ws['total_' + potencyType + '_mgpu'][val] ? ws['total_' + potencyType + '_mgpu'][val] + ' mg/serv' : '';
									}
								}
								else {
									if(ws['total_mgpu']['value'] == '0') {
										potency = '0 mg/serv';
									}
									else {
										potency = ws['total_mgpu'][val] ? ws['total_mgpu'][val] + ' mg/serv' : '';
									}
								}
							}
							if(!(['0', 0, null].includes(ws['moisture']['value'])) && ws['total_' + potencyType + '_dry_percent'] && potency == '') {
								if(!total) {
									if(ws['total_' + potencyType + '_dry_percent']['value'] == '0') {
										potency = '0% (dry)';
									}
									else {
										potency = ws['total_' + potencyType + '_dry_percent'][val] ? ws['total_' + potencyType + '_dry_percent'][val] + ' % (dry)' : '';
									}
								}
								else {
									if(ws['total_dry_percent']['value'] == '0') {
										potency = '0% (dry)';
									}
									else {
										potency = ws['total_dry_percent'][val] ? ws['total_dry_percent'][val] + ' % (dry)' : '';
									}
								}
							}
							if(!potency) {
								if(!total) {
									if(ws['total_' + potencyType + '_percent']['value'] == '0') {
										potency = '0%';
									}
									else {
										potency = ws['total_' + potencyType + '_percent'][val] ? ws['total_' + potencyType + '_percent'][val] + ' %' : '';
									}
								}
								else {
									if(['total_percent']['value'] == '0') {
										potency = '0%';
									}
									else {
										potency = ws['total_percent'][val] ? ws['total_percent'][val] + ' %' : '';
									}
								}
							}
						}
					}
				}
			}

			if(show_dash) {
				return potency ? potency : '-';
			}
			else {
				return potency;
			}
		}


		// function hasAlkaloidsMitragynine(){
		// 	if (vm.userAccount.userSettings) {
		// 		console.log(vm.userAccount.userSettings,'vm.userAccount.userSettingsssssss');
		// 		vm.userAccount.userSettings = JSON.stringify({sample_columns: vm.columns});
		// 	} else {
		// 		var userSettings = JSON.parse(vm.userAccount.userSettings);
		// 			userSettings.sample_columns = vm.sampleColumns;
		// 			console.log(vm.sampleColumns.mitragynine,'vm.sampleColumns');
		// 			if(vm.sampleColumns && !vm.sampleColumns.mitragynine){
		// 				if(vm.customerData.consolidated_business_type.includes('food')){
		// 					vm.sampleColumns.mitragynine = true;
		// 				}
		// 				else{
		// 					vm.sampleColumns.mitragynine = false;
		// 				}
		// 			}
		//python path error - resolve 			console.log(vm.sampleColumns.mitragynine,'vm.sampleColumns.mitragynine');
		// 	}
		// }

		function hasAlkaloidsMitragynine(){
			if(vm.customerData.consolidated_business_type.includes('kratom') ){
				vm.sampleColumns.alkaloids = true;
				vm.sampleColumns.mitragynine = true;
			}
			else{
				vm.sampleColumns.alkaloids = false;
				vm.sampleColumns.mitragynine = false;
			}
		}

		$scope.hasCannabinoids = function(sample) {
			if (!sample.sampleTests) {
				return false;
			}
			if(vm.cannabioidWorksheets) {
				for (var i=0; i < sample.sampleTests.length; i++) {
					if(!sample.sampleTests[i].assay) {
						continue;
					}

					if(vm.cannabioidWorksheets.includes(sample.sampleTests[i].assay.worksheet_id)) {
						return true;
					}
				}
			}
			return false;
		}

        function getTotalThc(sample) {
        	if (!sample.sampleTests) return;
        	for (var i = 0; i < sample.sampleTests.length; i++){
        		var test = sample.sampleTests[i];
        		if (!test.assay || !test.assay.portalIdentifier) continue;
        		var assay = test.assay.portalIdentifier;
        		if (assay === 'POTENCY_LCDAD' || assay === 'POTENCY_GCFID' || assay === 'POTENCY_LCMSMS' || assay === 'POTENCY_LCDAD_NON_PLANT') {
					if (test.worksheetData && test.worksheetData.total_dry_percent && test.worksheetData.total_dry_percent.twoDecimalFormattedValue) {
						return test.worksheetData.total_dry_percent.twoDecimalFormattedValue + ' %';
        			}
        		}
        	}
        	return 'N/A';
        }

        /* Edit Columns */
		function saveSampleColumns() {
			if (!vm.userAccount.userSettings) {
				vm.userAccount.userSettings = JSON.stringify({sample_columns: vm.columns});
			} else {
				var userSettings = JSON.parse(vm.userAccount.userSettings);
				userSettings.sample_columns = vm.sampleColumns;
				vm.userAccount.userSettings = JSON.stringify(userSettings);
			}
			Auth.updateAccount(vm.userAccount);
		}

		/* Page loads */
        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.sampleFilters = {};
            vm.populateTypes();
            vm.transition();
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
			var params = createFilterQueryParams();

        	if ($scope.galleryView || $stateParams.galleryView)
        		params['galleryView'] = true;


        	if ($scope.starred ||$stateParams.starred) {
				params['starred'] = true;
			}
        	else {
				params['starred'] = null;
			}

			if ($scope.isInProgress || $stateParams.isInProgress) {
				params['isInProgress'] = true;
			}
			else {
				params['isInProgress'] = null;
			}

			if ($scope.isCompleted || $stateParams.isCompleted) {
				params['isCompleted'] = true;
			}
			else {
				params['isCompleted'] = null;
			}

			$state.transitionTo($state.$current, params);
			vm.setTabViews();
        }

        function setpagesize(pagesize) {
            vm.itemsPerPage = pagesize;
            loadAll ();
        }

		function extractPackageValues(samples) {
			samples.forEach( function (sample){
				sample.sampleTests.forEach( function (test){
					if (test.worksheetData){
						if (test.worksheetData['total_thc_mgpp']){
							sample.thc_mgpp = test.worksheetData['total_thc_mgpp'].value;
						}
						if (test.worksheetData['total_cbd_mgpp']){
							sample.cbd_mgpp = test.worksheetData['total_cbd_mgpp'].value;
						}
						if (test.worksheetData['total_mgpp']){
							sample.total_mgpp = test.worksheetData['total_mgpp'].value;
						}
					}
				});
			});
		}

        /** Load ALL the Sample Images!
         * Ideally, make an endpoint to retrieve all of the sample images so you don't need to keep calling.
         * **/

        function loadAll () {
        	 if (vm.totalFilters > 0) {
        		 var params = createFilterQueryParams();
        		 params.page = pagingParams.page - 1;
        		 SampleFilter.query(params, onSuccess, onError);

        	 } else if (pagingParams.search) {
                SampleSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

            } else {
                 Sample.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    productCategoryId: vm.productCategoryId
                }, onSuccess, onError);

            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }

                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
				vm.samples = data;
				vm.samples.forEach( e => {
					e.public = e.is_public
				})
                vm.page = pagingParams.page;
				vm.pagingParams = pagingParams;

                loadAllPictures();
				extractPackageValues(vm.samples);

            }
            function onError(error) {
                AlertService.error(error.data.message);
            }

            function loadAllPictures() {
            	var sample_ids = []
            	var pictures = []
    	        for (var i = 0; i < vm.samples.length; i++) {
					sample_ids.push(vm.samples[i].hash);
    	        }
            	SampleAttach.getBulkPictures({ids: sample_ids},onSuccess,onError);

            	function onSuccess(pictures) {

                	for (var i = 0; i < vm.samples.length; i++) {
                		var pic = pictures[vm.samples[i].id][0].slice(-1)[0]
        	        	vm.samples[i].picture = pic ? pic.url : "content/images/no_image_available-small.png";
        	        }
            	}

            	function onError(error) {

            	}
            }
        }

        loadAll();
        if (vm.sampleFilters.category) {
        	vm.populateTypes();
		}

        angular.element(document).ready(function() {
            setTimeout(function () {

                $("#table tr:first > th").each(function(index, element) {
                    index++;
                    var addLi = "<li class='tbl-dot-" + index + "'><span class='tbl-dot'></span></li>";
                    $("ul.tbl-dots").append(addLi);
                });

                var num_col = $("#table tr:first > th").length;
                var constant = 2;
                var constant2 = constant + 1;

                var col_width = 100 / num_col;

                $(window).on("load resize",function(){
                  var winWidth = $(window).width();
                  if(768 >= winWidth) {
                      $("#table tr:first > th").css("width", col_width+"%");
                  }
                });

                $("#col-prev").click(function() {
                    constant -= 1;
                    constant2 -= 1;
                    if (constant == 2 || constant2 == 2) {
                        $("#col-prev").attr('disabled', 'disabled');
                        $("#col-next").prop("disabled", false);
                    }
                    $('.tbl-col-' + constant).show();
                    $('.tbl-col-' + constant2).show();
                    $('.tbl-dot-' + constant).removeClass('disabled').addClass('active');
                    var x = constant2 + 1;
                    $('.tbl-col-' + x).hide();
                    $('.tbl-dot-' + x).removeClass('active').addClass('disabled');
                    if(constant == 2 || constant2 >= 2){
                        $("#col-next").prop("disabled", false);
                    }
                });

                $("#col-next").click(function() {
                    constant += 1;
                    constant2 += 1;
                    $('.tbl-col-' + constant).show();
                    $('.tbl-col-' + constant2).show();
                    $('.tbl-dot-' + constant).removeClass('disabled').addClass('active');
                    $('.tbl-dot-' + constant2).removeClass('disabled').addClass('active');
                    var x = constant - 1;
                    var x2 = constant2 - 1;
                    $('.tbl-col-' + x).hide();
                    $('.tbl-dot-' + x).removeClass('active').addClass('disabled');
                    if (constant >= num_col || constant2 >= num_col) {
                        $("#col-next").attr('disabled', 'disabled');
                        $("#col-prev").prop("disabled", false);
                    }
                    $("#col-prev").prop("disabled", false);
                });

            }, 1000);
        });

    }
})();
