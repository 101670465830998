(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('CalendarController', CalendarController)
        .controller('CalendarDayModalController', CalendarDayModalController);

    CalendarController.$inject = ['$state','$stateParams','$scope','$rootScope', '$timeout', '$filter', 'ProductCategory', 'SampleFilter', 'Config', '$translate'];

    function CalendarController($state, $stateParams, $scope, $rootScope, $timeout, $filter, ProductCategory, SampleFilter, Config, $translate) {

		var vm = this;
		console.log("Processing calendar");
		vm.currentLanguage = $translate.proposedLanguage();
		$rootScope.pageTitle = $state.current.data.pageTitle;
		foodClientOptions()

        // Toggle Widgets
        vm.toggleWidget = function(name) {
        	vm.activeWidget = name === vm.activeWidget ? null : name;
		}

        // Filter Options
        vm.calendarFilters = {};
        vm.orderStatuses = [
            {text: 'Created', value: 'CREATED'},
            {text: 'Samples Received', value: 'SAMPLES_RECEIVED'},
            {text: 'Preliminary Results', value: 'PRELIMINARY_RESULTS'},
            {text: 'Completed', value: 'COMPLETED'}
        ]
        vm.filterOptions = [
        	{text: "R&D", value: 'RD'},
        	{text: "Compliance", value: 'COMPLIANCE'}
        ];

        vm.categories = [];
        var categoryNames = [];
        ProductCategory.query(function(categories){
        	if (!categories) return;
//        	for (var i = 0; i < categories.length; i++){
//        		vm.filterOptions.push({text: categories[i].name, value: categories[i].id});
//        		categoryNames.push(categories[i].name);
//        	}
			vm.categories = categories;
		});

		Config.holidays({},
			function(data) {
				var arrayOfHolidays = []
				data.holidays.forEach(element => {
					for(var i = moment().subtract(2, 'years').year(); i <= moment().add(2, 'years').year(); i++){
						var date = element.date.replace('2021',i.toString())
						arrayOfHolidays.push({date,name:element.name,imageUrl:element.imageUrl});
					} 
				})
				vm.holidays = arrayOfHolidays
				// vm.holidays = data.holidays
			},
			function(e) {
				console.log(e);
			}
		);

		vm.blackoutDates = []
		vm.blackoutDateswithNames = []

        vm.filterSamples = function() {
        	vm.calendarLoading = true;
        	vm.calendarHeader = $('#estimatedDueDateCalendar .fc-header-toolbar .fc-left h2').text();

        	SampleFilter.calendar(createQueryParams(), function(samples){
        		vm.calendarLoading = false;
				vm.calendarLoadError = false;
				
				var start_date = convertLocalDateToServer($('#estimatedDueDateCalendar').fullCalendar('getCalendar').view.start._d);
				var end_date = convertLocalDateToServer($('#estimatedDueDateCalendar').fullCalendar('getCalendar').view.end._d)
				for(var m = moment(start_date); m.diff(end_date, 'days') <= 0; m.add(1, 'days')) {						
					if([0, 6].includes(m.day())) {
						// if([0, 6].includes(m.day()) || vm.holidays.date.includes(m.format('YYYY-MM-DD'))) {
						vm.blackoutDates.push(m.format('YYYY-MM-DD'));
						vm.blackoutDateswithNames.push(m.format('YYYY-MM-DD'))
					}
				}
				vm.holidays.forEach(e => {
					var holiday = moment(e.date)
					vm.blackoutDates.push(holiday.format('YYYY-MM-DD'));
					vm.blackoutDateswithNames.push({
						date:holiday.format('YYYY-MM-DD'),
						name:e.name,
						imageUrl:e.imageUrl
					})
				})
        		var events = [];
        		if (samples && samples.length) {
        			for (var i = 0; i < samples.length; i++) {
        				var day = moment(samples[i].dueDate).toDate();
        				var event = {
        						id: samples[i].id,
        						name: samples[i].name ? samples[i].name : '',
        						title: samples[i].id + ' ' + samples[i].name,
        						start: day,
        						end: day,
        						allDay: true,
        						hash: samples[i].hash
        				}
        				events.push(event);
        			}
        		}
        		vm.events[0] = events;
				$('#estimatedDueDateCalendar').fullCalendar('getView').dayGrid.renderGrid();
				
        	}, function(error) {
        		vm.calendarLoading = false;
        		vm.calendarLoadError = true;
        	});
        }
		
        // Calendar
        vm.events = [];

        vm.prevMonth = function() { $('#estimatedDueDateCalendar > div.fc-toolbar.fc-header-toolbar > div.fc-right > div > button.fc-prev-button').click(); vm.filterSamples(); }
        vm.nextMonth = function() { $('#estimatedDueDateCalendar > div.fc-toolbar.fc-header-toolbar > div.fc-right > div > button.fc-next-button').click(); vm.filterSamples(); }
        vm.today = function() { $('#estimatedDueDateCalendar > div.fc-toolbar.fc-header-toolbar > div.fc-right > button.fc-today-button').click(); vm.filterSamples(); }

        vm.openPublicSample = function(event, jsEvent, view) { $state.go('public', {hash: event.hash}); }

        function openDayModal(date) {
			var modalParams = {
					samples: []
			};
			$('#estimatedDueDateCalendar').fullCalendar('clientEvents', function(event) {
				if (event.start.format('YYYY-MM-DD') === date) {
					modalParams.samples.push({
						id: event.id,
						name: event.name,
						hash: event.hash
					});
    				modalParams.date = event.start;
    				modalParams.queryParams = createQueryParams();
    				$state.go('calendar.dayModal', modalParams);
				}
			});
        }
		vm.backgroundImages = {
			'2023-01-01': 'content/images/calendar_images/NewYear.jpg',
			'2023-02-15': 'content/images/calendar_images/PresidentsDay.jpg',
			'2023-05-31': 'content/images/calendar_images/MemorialDay.jpg',
			'2023-07-04': 'content/images/calendar_images/4thJuly.jpg',
			'2023-09-06': 'content/images/calendar_images/LaborDay.jpg',
			'2023-11-25': 'content/images/calendar_images/ThanksGiving.jpg',
			'2023-11-26': 'content/images/calendar_images/ThanksGiving1.jpg',
			'2023-12-24': 'content/images/calendar_images/Christmas.jpg',
			'2023-12-25': 'content/images/calendar_images/Christmas1.jpg',
			'2023-12-31': 'content/images/calendar_images/newYearEve.jpg',

			'2024-01-01': 'content/images/calendar_images/NewYear.jpg',
			'2024-02-15': 'content/images/calendar_images/PresidentsDay.jpg',
			'2024-05-27': 'content/images/calendar_images/MemorialDay.jpg',
			'2024-07-04': 'content/images/calendar_images/4thJuly.jpg',
			'2024-09-02': 'content/images/calendar_images/LaborDay.jpg',
			'2024-11-28': 'content/images/calendar_images/ThanksGiving.jpg',
			'2024-11-29': 'content/images/calendar_images/ThanksGiving1.jpg',
			'2024-12-24': 'content/images/calendar_images/Christmas.jpg',
			'2024-12-25': 'content/images/calendar_images/Christmas1.jpg',
			'2024-12-31': 'content/images/calendar_images/newYearEve.jpg',

			'2025-01-01': 'content/images/calendar_images/NewYear.jpg',
			'2025-02-17': 'content/images/calendar_images/PresidentsDay.jpg',
			'2025-05-26': 'content/images/calendar_images/MemorialDay.jpg',
			'2025-07-04': 'content/images/calendar_images/4thJuly.jpg',
			'2025-09-01': 'content/images/calendar_images/LaborDay.jpg',
			'2025-11-27': 'content/images/calendar_images/ThanksGiving.jpg',
			'2025-11-28': 'content/images/calendar_images/ThanksGiving1.jpg',
			'2025-12-24': 'content/images/calendar_images/Christmas.jpg',
			'2025-12-25': 'content/images/calendar_images/Christmas1.jpg',
			'2025-12-31': 'content/images/calendar_images/newYearEve.jpg'
			
		};
        vm.calendarOptions = {
        		firstDay: 1,
    			dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    			dayNamesShort: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    			eventLimit: true,
    			eventLimitClick: function(cellInfo, jsEvent) {
    				var modalParams = {samples: []};
    				for (var i = 0; i < cellInfo.segs.length; i++){
    					var def = cellInfo.segs[i].footprint.eventInstance.def;
    					modalParams.samples.push({
    						id: def.id,
    						name: def.miscProps.name,
    						hash: def.miscProps.hash
    					});
    				}
    				modalParams.date = cellInfo.date;
    				modalParams.queryParams = createQueryParams();
    				$state.go('calendar.dayModal', modalParams);
    			},
    			eventRender: function(event, element) {
					return '<div class="calendar-sample-event"><strong>' + event.id + '</strong> ' + event.name + '</div>'
    			},
    			eventClick: vm.openPublicSample,
    			viewRender: vm.filterSamples,
    			dayRender: function(date, cell) {
    				cell.append('<div class="calendar-day-no-samples">'+$translate.instant("No Samples")+'</div>');
    				var thing = $('<a class="calendar-see-list-btn" data-date="' + date.format('YYYY-MM-DD') + '">'+ $translate.instant("see list") +'</a>');
    				thing.click(function(){
    					openDayModal(date.format('YYYY-MM-DD'));
    				});
    				cell.append(thing);
					var currentDate = date.format('YYYY-MM-DD');
					if (currentDate in vm.backgroundImages) {
						var cellId = 'cell_' + currentDate;
						cell.attr('id', cellId);
						var backgroundImageUrl = vm.backgroundImages[currentDate];
						cell.css('background-image', 'url(' +backgroundImageUrl+ ')');
						cell.css('object-fit','contain')
						cell.css('background-size','100% 100%');
						cell.find('div.calendar-day-no-samples').css('color', '#00000000');
						cell.addClass('cell-with-image');

						// Remove "Closed" text on days with events
						var dayOfWeek = moment(date).day();
						if (dayOfWeek === 0 || dayOfWeek === 6) {
							cell.find('.fc-day-top').text('');
							cell.find('div.calendar-day-no-samples').remove();
						}
					}
					
				},
    			eventAfterAllRender: function(view) {
    				// Remove "No Samples" text on days with events
    				$('#estimatedDueDateCalendar').fullCalendar('clientEvents', function(event) {
        				var td = $('td.fc-day[data-date="' + event.start.format('YYYY-MM-DD') + '"]');
        				td.find('div.calendar-day-no-samples').remove();
        				var seeListBtn = td.find('a.calendar-see-list-btn');
        				seeListBtn.show();

						});
						var blackout = null;
						var blackoutCount = null;
						vm.blackoutDates.forEach(function (date, i) {
							if (vm.blackoutDateswithNames[i].date === date) {
								 blackoutCount = $('td.fc-day-top[data-date="' + date + '"]')
								 blackoutCount.css('visibility', 'hidden');
							}
						
						else{
							blackout = $('td.fc-day[data-date="' + date + '"]');
							blackout.css('background-color', '#f7f7f7');
							blackout.find('div.calendar-day-no-samples').css('color', '#c5ced1')
							.text('Closed');
						}
					});

    				$('.fc-more-cell').css('text-align', 'right');
    				$('a.fc-more').css('color', '#f1b21a');	

    				// Assign click event to "see list" button
    				$('.fc-more').each(function(){
    					//var seeListBtn = $('.calendar-see-list-btn[data-date="' +)
    				});
    			}
		}

		function foodClientOptions() {
			if($rootScope.customer) {
				if($rootScope.customer.customer_type.toLowerCase().includes('food')) {
					vm.consolidated_business_type = 'food';
				}
			}

			if(!vm.consolidated_business_type) {
				vm.consolidated_business_type = 'cannabis';
			}
		}

        function createQueryParams() {
        	var params = {};
        	params.estimatedDueDateLower = convertLocalDateToServer($('#estimatedDueDateCalendar').fullCalendar('getCalendar').view.start._d);
        	params.estimatedDueDateUpper = convertLocalDateToServer($('#estimatedDueDateCalendar').fullCalendar('getCalendar').view.end._d);

        	for (var key in vm.calendarFilters) {
        		if (!vm.calendarFilters[key]) continue;
        		params[key] = vm.calendarFilters[key];
			}
			
        	return params;
        }

        /* Date Utils (import DateUtils service doesn't work) */
        function convertLocalDateFromServer(date) {
    		if (!date) return;

    		if (date.indexOf('Z') === -1 && utcDateString.indexOf('+') === -1) utcDateString += 'Z';

            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }
    }

    CalendarDayModalController.$inject = ['$state','$stateParams','$scope','$rootScope', '$timeout', '$filter', '$uibModalInstance', 'PreviousState'];

    function CalendarDayModalController($state, $stateParams, $scope, $rootScope, $timeout, $filter, $uibModalInstance, PreviousState) {
    	var vm = this;

    	$scope.$on('$stateChangeStart', function() {
    		$scope.stateChange();
    	});
    	$scope.stateChange = function() {
    		$uibModalInstance.dismiss('cancel');
    	}

    	vm.date = $stateParams.date;
    	vm.samples = $stateParams.samples;
    	vm.queryParams = $stateParams.queryParams;

    	vm.closeModal = function() { $state.go(PreviousState.name); }

    	vm.totalSamples = vm.samples ? vm.samples.length : 0;
    	if (vm.samples) {
        	for (var i = 0; i < vm.samples.length; i++) {
        		if (vm.samples[i].id == -1) {
        			vm.totalSamples -= 1;
        			break;
        		}
        	}
    	}

    	vm.totalSamplesText = vm.totalSamples == 1 ? '1 Sample' : vm.totalSamples + ' total samples';

        vm.openPublicSample = function(event, jsEvent, view) {
        	$state.go('public', {hash: event.hash});
        }

        vm.toSamplesPage = function() {
        	var dateString = vm.date.format('YYYY-MM-DD');
        	vm.queryParams.estimatedDueDateLower = dateString;
        	vm.queryParams.estimatedDueDateUpper = dateString;


        	$state.go('samplelog', vm.queryParams);
        }
    }
})();
